const BASE_API_URL = process.env.SPEEDABLE_API_URL;

const { RUNA_KEY } = process.env;

// Function to get the stored JWT token
function getToken() {
  return localStorage.getItem('jwtToken');
}

// Helper function to initialize fetch request headers with Authorization
function getAuthHeaders(contentType = 'application/json') {
  const token = getToken();
  const headers = {
    'Content-Type': contentType,
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
}

// google integration

// Add the getGoogleIntegrationURL function
async function getGoogleIntegrationURL() {
  const response = await fetch(`${BASE_API_URL}/auth/google/start`, {
    method: 'GET',
    headers: getAuthHeaders(), // Ensure authentication headers are included
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json(); // This should return the URL to initiate the OAuth flow
}

async function getGoogleRefreshIntegrationURL() {
  const response = await fetch(`${BASE_API_URL}/auth/google/refresh`, {
    method: 'GET',
    headers: getAuthHeaders(), // Ensure authentication headers are included
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json(); // This should return the URL to initiate the OAuth flow
}

async function removeGoogleIntegration(integrationId) {
  const response = await fetch(`${BASE_API_URL}/auth/google/remove`, {
    method: 'DELETE',
    headers: getAuthHeaders(), // Ensure authentication headers are included
    body: JSON.stringify({ "integration_id": integrationId }),
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json(); // This should return the URL to initiate the OAuth flow
}


async function saveGoogleTokens(authCode) {
  const response = await fetch(`${BASE_API_URL}/auth/google/redirect`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify({ code: authCode }),
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

// Users
async function createUser(userData) {
  const headers = getAuthHeaders();
  const response = await fetch(`${BASE_API_URL}/users/`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(userData),
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

async function getUserStatus(email) {
  const headers = getAuthHeaders();
  const response = await fetch(`${BASE_API_URL}/users/email/${email}`, {
    method: 'GET',
    headers: headers,
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  const data = await response.json();
  return data['UserStatus'];
}

async function resetUserPassword(userData) {
  const headers = getAuthHeaders();
  const response = await fetch(`${BASE_API_URL}/users/reset-password/`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(userData),
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

async function updateUser(userId, userData) {
  const response = await fetch(`${BASE_API_URL}/users/${userId}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(userData),
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

async function deleteUserProfile(userId, userData) {
  const response = await fetch(`${BASE_API_URL}/users/${userId}`, {
    method: 'DELETE',
    headers: getAuthHeaders(),
    body: JSON.stringify(userData),
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

// Function to get a user by their Cognito sub
async function getUserByCognitoSub(cognitoSub) {
  const response = await fetch(`${BASE_API_URL}/users/findbysub/${cognitoSub}`, {
    method: 'GET',
    headers: getAuthHeaders(),
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

// Campaigns
async function createCampaign(campaignData) {
  const response = await fetch(`${BASE_API_URL}/campaigns/`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(campaignData),
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

async function getCampaigns() {
  const response = await fetch(`${BASE_API_URL}/campaigns/`, {
    method: 'GET',
    headers: getAuthHeaders(), // Ensure authentication headers are included
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

async function updateCampaign(id, campaignData) {
  const response = await fetch(`${BASE_API_URL}/campaigns/${id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(campaignData),
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

async function deleteCampaign(id) {
  const response = await fetch(`${BASE_API_URL}/campaigns/${id}`, {
    method: 'DELETE',
    headers: getAuthHeaders(), // Include auth headers here
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

  // Only attempt to parse the response as JSON if the content exists
  if (response.status !== 204) {
    return await response.json();
  } else {
    // If it's a 204 No Content, simply return a success message or null
    return { message: 'Deleted successfully' };
  }
}

async function getCampaign(id) {
  const response = await fetch(`${BASE_API_URL}/campaigns/${id}`, {
    method: 'GET',
    headers: getAuthHeaders(), // Include auth headers here
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

// Threads
async function createThread(threadData) {
  const response = await fetch(`${BASE_API_URL}/threads/`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(threadData),
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

async function updateThread(id, threadData) {
  const response = await fetch(`${BASE_API_URL}/threads/${id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(threadData),
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

async function deleteThread(id) {
  const response = await fetch(`${BASE_API_URL}/threads/${id}`, {
    method: 'DELETE',
    headers: getAuthHeaders(), // Include the auth headers here
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

async function getThread(id) {
  const response = await fetch(`${BASE_API_URL}/threads/${id}`, {
    method: 'GET',
    headers: getAuthHeaders(), // Include the auth headers here
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

async function getThreadsByCampaignId(campaignId) {
  const response = await fetch(`${BASE_API_URL}/threads/by-campaign/${campaignId}`, {
    method: 'GET',
    headers: getAuthHeaders(), // Use the existing getAuthHeaders to include the Authorization header
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

// Function to fetch Gmail drafts
async function fetchGmailDrafts() {
  const response = await fetch(`${BASE_API_URL}/gmail/drafts`, {
    method: 'GET',
    headers: getAuthHeaders(),
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

// Function to fetch Gmail threads within a specified date range
async function fetchGmailThreads(startDate, endDate) {
  // Assuming startDate and endDate are formatted as 'YYYY-MM-DD'
  const response = await fetch(`${BASE_API_URL}/gmail/threads?start_date=${startDate}&end_date=${endDate}`, {
    method: 'GET',
    headers: getAuthHeaders(),
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

async function setupGmailInboxWatch() {
  const response = await fetch(`${BASE_API_URL}/setup-inbox-watch`, {
    method: 'POST',
    headers: getAuthHeaders(), // Use the existing getAuthHeaders to include the Authorization header
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

// Recipients
async function createRecipient(recipientData) {
  const response = await fetch(`${BASE_API_URL}/recipients/`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(recipientData),
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

async function updateRecipient(id, recipientData) {
  const response = await fetch(`${BASE_API_URL}/recipients/${id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(recipientData),
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

async function deleteRecipient(id) {
  const response = await fetch(`${BASE_API_URL}/recipients/${id}`, { method: 'DELETE' });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

async function getRecipient(id) {
  const response = await fetch(`${BASE_API_URL}/recipients/${id}`);
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

async function getRecipientsByThreadId(threadId) {
  const response = await fetch(`${BASE_API_URL}/recipients/by-thread/${threadId}`, {
    method: 'GET',
    headers: getAuthHeaders(),
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

async function createCampaignIncentive(incentiveData) {
  const response = await fetch(`${BASE_API_URL}/campaign-incentives/`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(incentiveData),
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

async function savePaymentMethod(paymentMethodId) {
  const response = await fetch(`${BASE_API_URL}/billing/save-payment-method`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ payment_method_id: paymentMethodId }),
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

async function getPaymentMethods() {
  const response = await fetch(`${BASE_API_URL}/billing/payment-methods/`, {
    method: 'GET',
    headers: getAuthHeaders(),
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

async function deletePaymentMethod(paymentMethodId) {
  const response = await fetch(`${BASE_API_URL}/billing/delete-payment-method/${paymentMethodId}`, {
    method: 'DELETE',
    headers: getAuthHeaders(),
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

async function setDefaultPaymentMethod(paymentMethodId) {
  const response = await fetch(`${BASE_API_URL}/billing/set-default-payment-method`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ payment_method_id: paymentMethodId }),
  });
  if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
  return await response.json();
}

// Function to rewards a reward with campaignId, threadId, and recipientId
async function redeemReward(campaignId, threadId, recipientId) {
  const url = `${BASE_API_URL}/redeem/${campaignId}/${threadId}/${recipientId}`;
  const response = await fetch(url, {
    method: 'POST', // POST since this operation modifies server state
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  return await response.json();
}

async function getCampaignRewards(campaignId) {
  const response = await fetch(`${BASE_API_URL}/campaigns/${campaignId}/rewards`, {
    method: 'GET',
    headers: getAuthHeaders(),
  });
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  return await response.json();
}

async function assignRewardToRecipient(recipientId, productId) {
  const response = await fetch(`${BASE_API_URL}/recipients/${recipientId}/assign-reward/${productId}`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({}),
  });
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  return await response.json();
}

async function getRewards() {
  const response = await fetch(`${BASE_API_URL}/rewards`, {
    method: 'GET',
    headers: getAuthHeaders(),
  });
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  return await response.json();
}

async function getIntegrations() {
  const response = await fetch(`${BASE_API_URL}/integrations`, {
    method: 'GET',
    headers: getAuthHeaders(),
  });
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  return await response.json();
}

export {
  getUserStatus,
  createCampaign,
  getCampaigns,
  updateCampaign,
  deleteCampaign,
  getCampaign,
  getCampaignRewards,
  createThread,
  updateThread,
  deleteThread,
  getThread,
  getThreadsByCampaignId,
  getRecipientsByThreadId,
  createRecipient,
  updateRecipient,
  deleteRecipient,
  getRecipient,
  assignRewardToRecipient,
  createUser,
  updateUser,
  getIntegrations,
  deleteUserProfile,
  getUserByCognitoSub,
  getGoogleIntegrationURL,
  getGoogleRefreshIntegrationURL,
  removeGoogleIntegration,
  saveGoogleTokens,
  fetchGmailDrafts,
  fetchGmailThreads,
  setupGmailInboxWatch,
  createCampaignIncentive,
  savePaymentMethod,
  getPaymentMethods,
  deletePaymentMethod,
  setDefaultPaymentMethod,
  redeemReward,
  resetUserPassword,
  getRewards,
};

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuth } from '../../services/auth/AuthService';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  Paper,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  getCampaign,
  getThreadsByCampaignId,
  getCampaignRewards,
  updateCampaign,
  getPaymentMethods,
  deleteThread,
} from '../../services/api/ApiService';
import AddNewThread from './AddNewThread'; // Adjust the import path as needed

function CampaignDetail() {
  const { id } = useParams();
  const [campaign, setCampaign] = useState(null);
  const [error, setError] = useState('');
  const [threads, setThreads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate(); // Initialize the useNavigate hook
  const theme = useTheme();
  const { userDetails } = useAuth();
  const [rewards, setRewards] = useState([]);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const [liveButtonDisabled, setLiveButtonDisabled] = useState(true);
  const [incentiveMentioned, setIncentiveMentioned] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [showCopyMsg, setShowCopyMsg] = useState(false);
  const [livePopupOpen, setLivePopupOpen] = useState(false);
  const exampleText =
    'We use a service called Speedable to accelerate email comms. Reply within [time] to receive [reward].';

  const handleCopyText = () => {
    navigator.clipboard.writeText(exampleText);
    setShowCopyMsg(true);
    setTimeout(() => {
      setShowCopyMsg(false);
      setPopupOpen(false);
    }, 750);
  };
  // Inside CampaignDetail component
  const fetchThreads = async () => {
    try {
      const fetchedThreads = await getThreadsByCampaignId(id);
      setThreads(fetchedThreads);
    } catch (error) {
      console.error('Failed to fetch threads:', error);
    }
  };

  const fetchRewards = async () => {
    try {
      const fetchedRewards = await getCampaignRewards(id);
      setRewards(fetchedRewards);
    } catch (error) {
      console.error('Failed to fetch rewards:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const paymentMethods = await getPaymentMethods(userDetails);
        const isValidPaymentMethod = paymentMethods.data.some((method) => method.livemode === true);
        setHasPaymentMethod(isValidPaymentMethod);
        const fetchedCampaign = await getCampaign(id);
        const fetchedThreads = await getThreadsByCampaignId(id);
        await fetchRewards();
        setCampaign(fetchedCampaign);
        setThreads(fetchedThreads);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (campaign && campaign.status === 'live') {
      setLiveButtonDisabled(true);
      setError('');
    } else if (hasPaymentMethod && incentiveMentioned) {
      setLiveButtonDisabled(false);
      setError('');
    } else if (hasPaymentMethod) {
      setError('Please mention the incentive in your email.');
      setLiveButtonDisabled(true);
    } else if (incentiveMentioned) {
      setError('Please add a payment method.');
      setLiveButtonDisabled(true);
    }
  }, [hasPaymentMethod, incentiveMentioned]);

  useEffect(() => {
    console.log(threads)
  }, [threads]);

  const handleDeleteThread = async (threadId) => {
    try {
      await deleteThread(threadId);
      setThreads((currentThreads) => currentThreads.filter((thread) => thread.id !== threadId));
    } catch (error) {
      console.error(`Failed to delete thread: ${error}`);
    }
  };

  const changeCampaignStatus = async (status) => {
    const data = await updateCampaign(id, { status: status });
    setCampaign(data);
    if (status === 'live') {
      setLivePopupOpen(true);
    } else if (status === 'inactive') {
      setIncentiveMentioned(false);
    }
  };
  return (
    <>
      {isLoading ? (
        <Box sx={{ p: 4 }}>
          <CircularProgress />
        </Box>
      ) : campaign ? (
        <>
          <Box sx={{ p: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" component="h1" sx={{ pb: 1, borderBottom: '1px solid #ccc' }}>
                  Campaign: {campaign.name}
                </Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>
                  Status: {campaign.status}
                </Typography>
                <Typography variant="body1" sx={{ mt: 2, fontWeight: 'bold' }}>
                  Rewards will not be sent or redeemable until the campaign is live.
                </Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>
                  Created At: {campaign.created_at}
                </Typography>
                {error && (
                  <Typography variant="body1" sx={{ mt: 2, color: 'red' }}>
                    {error}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="h6" sx={{ mb: 1, p: 3, borderBottom: '1px solid #ccc' }}>
                    Campaign Rewards
                  </Typography>
                  <TableContainer>
                    <Table aria-label="campaign rewards">
                      <TableHead>
                        <TableRow>
                          <TableCell>Product Name</TableCell>
                          <TableCell>Denomination</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rewards.map((reward) => (
                          <TableRow key={reward.id}>
                            <TableCell>{reward.product_name}</TableCell>
                            <TableCell>{reward.denomination.toFixed(2)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 2 }}>
                  <AddNewThread campaignId={id} onThreadsUpdated={fetchThreads} />
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper>
                  <Typography variant="h6" sx={{ p: 3, borderBottom: '1px solid #ccc' }}>
                    Threads
                  </Typography>
                  <TableContainer>
                    <Table aria-label="associated threads">
                      <TableBody>
                        {threads.map((thread) => (
                          <TableRow key={thread.id}>
                            <TableCell>
                              <Button
                                variant="text"
                                color="secondary"
                                sx={{
                                  '&:hover': {
                                    backgroundColor: theme.palette.secondary.light,
                                    color: '#fff',
                                  },
                                }}
                                onClick={() => navigate(`/threads/${thread.id}`)}
                              >
                                {thread.summary}
                              </Button>
                            </TableCell>
                            <TableCell>{thread.created_at}</TableCell>
                            <TableCell>
                              <Button variant="outlined" color="error" onClick={() => handleDeleteThread(thread.id)}>
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>

                <Table>
                  {campaign.status !== 'live' ? (
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ border: 'none' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={incentiveMentioned}
                                onChange={(e) => setIncentiveMentioned(e.target.checked)}
                                name="incentiveMentioned"
                                color="primary"
                              />
                            }
                            label="I've mentioned the incentive in my email"
                          />
                        </TableCell>
                        <TableCell style={{ border: 'none' }}>
                          <Button
                            onClick={() => changeCampaignStatus('live')}
                            disabled={liveButtonDisabled}
                            variant="contained"
                            color="primary"
                            sx={{ mt: 2 }}
                          >
                            Set Campaign Live
                          </Button>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ border: 'none' }}>
                          <Button variant="outlined" onClick={() => setPopupOpen(true)} sx={{ mt: 2 }}>
                            Show Example Text
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Button
                            onClick={() => changeCampaignStatus('inactive')}
                            variant="contained"
                            color="error"
                            style={{ marginTop: '16px' }}
                          >
                            Deactivate Campaign
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
                {/* Popup (Dialog) for showing example text */}
                <Dialog open={popupOpen} onClose={() => setPopupOpen(false)}>
                  <DialogTitle>Example Text</DialogTitle>
                  <DialogContent>
                    <Typography>{exampleText}</Typography>
                    {showCopyMsg && <Typography sx={{ color: 'green' }}>Copied to clipboard!</Typography>}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCopyText} color="primary">
                      Copy to Clipboard
                    </Button>
                  </DialogActions>
                </Dialog>
                {/* Popup (Dialog) for showing example text */}
                <Dialog open={livePopupOpen} onClose={() => setLivePopupOpen(false)}>
                  <DialogTitle>Campaign Live</DialogTitle>
                  <DialogContent>
                    <Typography>You can now send your email drafts.</Typography>
                    <Typography>If your recipients meet your deadline, we'll send them their rewards</Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setLivePopupOpen(false)} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <Box sx={{ p: 4 }}>
          <Typography variant="h5">Campaign not found</Typography>
        </Box>
      )}
    </>
  );
}

export default CampaignDetail;

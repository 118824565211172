import React, { useState, useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  MenuItem,
  Menu,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  useMediaQuery,
} from '@mui/material';
import RedeemIcon from '@mui/icons-material/Redeem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DashboardIcon from '@mui/icons-material/Dashboard';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import { useAuth } from '../services/auth/AuthService';
import SpeedLogo from '../assets/speed-logo.svg';
import RewardsListItem from '../components/RewardsListItem';

function PrivateLayout() {
  const { isAuthenticated, signOut, userDetails, loading } = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const [listOpen, setListOpen] = useState(false); // State to manage the list's open/close status

  const matchesSM = useMediaQuery(theme.breakpoints.up('md'));
  const [prevMatch, setPrevMatch] = useState(matchesSM);

  // Only proceed when loading is done
  useEffect(() => {
    if (!loading && !isAuthenticated) {
      navigate('/login'); // Redirect to login only when not authenticated
    }
  }, [isAuthenticated, loading, navigate]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    handleClose();
    navigate('/profile');
  };

  const handleNavigate = (path) => {
    if (!matchesSM) {
      setListOpen(false);
    }
    navigate(path);
  };

  const toggleList = () => {
    setListOpen(!listOpen); // Toggle the list open/close state
  };

  useEffect(() => {
    if (!matchesSM) {
      setListOpen(false);
    }

    // If transitioning from sm+ to xs, collapse the sidebar
    if (prevMatch && !matchesSM) {
      setListOpen(false);
    }

    // If transitioning from xs to sm+, expand the sidebar
    else if (matchesSM) {
      setListOpen(true);
    }
    setPrevMatch(matchesSM);
  }, [matchesSM, prevMatch]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6">Checking Authentication...</Typography>
      </div>
    );
  }
  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img
              src={SpeedLogo}
              alt="Speedable Logo"
              style={{ height: '40px', marginRight: '10px', marginTop: '10px' }}
              sx={{ flexGrow: 1 }}
            />
          </Typography>
          {userDetails && (
            <>
              <Typography variant="subtitle1" sx={{ marginRight: 2 }}>
                {userDetails.email} {/* Displaying user email */}
              </Typography>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
                <MenuItem onClick={signOut}>Logout</MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Box sx={{ display: 'block', width: '100%', mt: { xs: 7, sm: 8 } }}>
        <Grid container spacing={0} sx={{ height: '100%' }}>
          <Box
            sx={{
              height: 64,
              maxHeight: 64,
              width: {
                xs: '100%',
                md: '250px',
              },
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                display: {
                  xs: 'flex',
                  md: 'none',
                },
                alignItems: 'center',
                justifyContent: 'flex-start',
                p: 1,
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.primary.contrastText,
              }}
            >
              <Typography sx={{ flexGrow: 1, display: { md: 'none' }, textAlign: 'right' }}>Menu</Typography>
              <IconButton sx={{ display: { md: 'none', color: '#fff' } }} onClick={toggleList}>
                {listOpen ? <ExpandMoreIcon /> : <MenuIcon />}
              </IconButton>
            </Box>
            <Collapse in={listOpen} timeout="auto" unmountOnExit>
              <Paper
                sx={{
                  position: 'relative',
                  height: { xs: 'auto', md: 'calc(100vh - 64px)' },
                  boxSizing: 'border-box',
                  width: '100%',
                  overflow: 'auto',
                }}
              >
                <List>
                  <ListItem
                    sx={{
                      '&:hover': {
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.primary.light,
                      },
                    }}
                    button
                    onClick={() => handleNavigate('/integrations')}
                  >
                    <ListItemIcon>
                      <IntegrationInstructionsIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography sx={{ fontSize: 18, p: 1 }} variant="body1">
                          Integrations
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem
                    sx={{
                      '&:hover': {
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.primary.light,
                      },
                    }}
                    button
                    onClick={() => handleNavigate('/campaigns')}
                  >
                    <ListItemIcon>
                      <LeaderboardIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography sx={{ fontSize: 18, p: 1 }} variant="body1">
                          Campaigns
                        </Typography>
                      }
                    />
                  </ListItem>
                  <RewardsListItem handleNavigate={handleNavigate} />
                  <ListItem
                    sx={{
                      '&:hover': {
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.primary.light,
                      },
                    }}
                    button
                    onClick={() => handleNavigate('/billing')}
                  >
                    <ListItemIcon>
                      <PriceChangeIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography sx={{ fontSize: 18, p: 1 }} variant="body1">
                          Billing
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </Paper>
            </Collapse>
          </Box>
          <Box
            item="true"
            xl={10.5}
            lg={10.5}
            md={9}
            sm={12}
            xs={12}
            sx={{ width: { xs: '100%', md: 'calc(100% - 250px)' }, height: '100%', overflow: 'auto' }}
          >
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                p: { xs: 0, md: 3 },
                height: 'auto',
                boxSizing: 'border-box',
                borderTop: '20px solid #eee',
                borderLeft: '20px solid #eee',
                borderRight: '20px solid #eee',
                backgroundColor: '#fff',
              }}
            >
              <Outlet />
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default PrivateLayout;
